
import { defineComponent, computed, ref, onMounted } from "vue";
import CloudFun from "@cloudfun/core";
import { menu } from "ionicons/icons";
import { useRoute } from 'vue-router';
export default defineComponent({
  name: "TopBar",
  setup() {
    const route = useRoute();
    const mainPage: any = ref({path: '/'});
    onMounted(() => {
      console.log("route.name", route.name)
      if(route.name === 'step1') {
        mainPage.value = {path: '/step1', query: { id: route.query?.id}};
      }
      else if(localStorage.getItem("AreaId")){
        var data: any = localStorage.getItem("AreaId");
        let areaId = JSON.parse(data);
        mainPage.value = {path: '/step1', query: { id: JSON.parse(areaId) }};
      }
    })
    
    const user = CloudFun.current?.user;
    const isLogin = computed(() => !!user?.Id)
    return {
      menu,
      isLogin,
      mainPage
    };
  }
});
