
import { defineComponent, onMounted, ref } from "vue";
import CloudFun from "@cloudfun/core";
import { onIonViewDidEnter } from "@ionic/vue";
import { computed } from "@vue/reactivity";

export default defineComponent({
  setup() {
    const model = CloudFun.current?.model;
    const configuration = ref({} as any);
    const bussinessHours = computed(() => {
      const open = configuration.value.OpenTime?.split(':').slice(0, -1).join(':');
      const close = configuration.value.CloseTime?.split(':').slice(0, -1).join(':');
      return open + "~" + close;
    })
    onMounted(async () => {
      configuration.value = await model!.dispatch('configuration/read');
    })
    onIonViewDidEnter(async () => {
      console.log("type", "attrs.name")
    })
    return {
      configuration,
      bussinessHours
    };
  }
});
