
import { defineComponent, computed } from "vue";
import CloudFun from "@cloudfun/core"
import { close } from "ionicons/icons";

export default defineComponent({
  name: "Menu",
  props: {
    contentId: String,
  },
  setup() {
    const user = CloudFun.current?.user;
    const isLogin = computed(() => !!user?.Id)
    return {
      close,
      isLogin
    };
  },
  methods: {
  },
});
