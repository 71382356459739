
import { defineComponent } from "vue";
import { chevronBack, chevronForward } from "ionicons/icons";
export default defineComponent({
	setup() {
		return {
			chevronBack,
			chevronForward
		}
	}
});
