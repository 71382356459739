import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex items-center" }
const _hoisted_2 = { class: "text-gray-9 font-bold text-base" }
const _hoisted_3 = {
  class: "ion-padding text-black bg-green-1",
  slot: "content"
}
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_accordion = _resolveComponent("ion-accordion")!
  const _component_ion_accordion_group = _resolveComponent("ion-accordion-group")!

  return (_openBlock(), _createBlock(_component_ion_accordion_group, null, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.templates, (item) => {
        return (_openBlock(), _createElementBlock("div", {
          key: item.index
        }, [
          _createVNode(_component_ion_accordion, {
            value: item.index,
            "toggle-icon": _ctx.arrowForwardOutline,
            "toggle-icon-slot": "end",
            class: "faqAccordion"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, {
                slot: "header",
                color: "green"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_1, [
                        _createElementVNode("i", {
                          class: _normalizeClass(["text-green-4 text-3xl", item.icon])
                        }, null, 2),
                        _createElementVNode("span", _hoisted_2, _toDisplayString(item.title), 1)
                      ])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024),
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", {
                  class: "wysiwyg",
                  innerHTML: item.content
                }, null, 8, _hoisted_4)
              ])
            ]),
            _: 2
          }, 1032, ["value", "toggle-icon"])
        ]))
      }), 128))
    ]),
    _: 1
  }))
}