/* eslint-disable */

import context, { IConfiguration, IJsonResponse, ModelState } from "@cloudfun/core";
import { Module } from "vuex";

const apiPath = "/Portal/System";

interface State {
}

const module: Module<State, ModelState> = {
    namespaced: true,
    state: {
    },
    getters: {
    },
    mutations: {
    },
    actions: {
      validateCaptcha: ({ rootState, commit }, data) => new Promise((resolve, reject) => {
        rootState.clients.authorized.post(`${apiPath}/Captcha/Validate`, data).then(
          (success: IJsonResponse<any>) => {
            commit('setLoadingTime', null); // reset loading time
            resolve(success.payload!);
          },
          (failure: IJsonResponse<any>) => reject(failure.message)
        )
      }),
    }
}

export default module;
