import { createRouter, createWebHashHistory } from "@ionic/vue-router";
import { RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  // {
  //   path: "/tabs/",
  //   component: TabsPage,
  //   children: [
  //     {
  //       path: "",
  //       redirect: "/tabs/area",
  //     },
  //     {
  //       path: "area",
  //       component: () => import("@/views/area/Main.vue"),
  //     },
  //     {
  //       path: "member",
  //       component: () => import("@/views/member/Main.vue"),
  //     },
  //     {
  //       path: "tab2",
  //       component: () => import("@/views/Tab2Page.vue"),
  //     },
  //     {
  //       path: "tab3",
  //       component: () => import("@/views/Tab3Page.vue"),
  //     },
  //     {
  //       path: "travel",
  //       component: () => import("@/views/TravelPage.vue"),
  //     },
  //   ],
  // },
  // {
  //   path: "/member",
  //   component: () => import("@/views/member/Main.vue"),
  // },
  {
    path: "/login",
    component: () => import("@/views/member/Login.vue"),
  },
  // {
  //   path: "/renting/:id/:name",
  //   component: () => import("@/views/renting/Main.vue"),
  // },
  // {
  //   path: "/cart",
  //   component: () => import("@/views/cart/Main.vue"),
  // },
  // {
  //   path: "/cart/confirm/:data",
  //   name: "confirm-data",
  //   component: () => import("@/views/cart/Confirm.vue"),
  //   props: true,
  // },
  // {
  //   path: "/order/detail/:id/:allowBack",
  //   name: "orderDetail",
  //   component: () => import("@/views/order/Detail.vue"),
  //   props: true,
  // },
  // {
  //   path: "/order/booking/:id",
  //   name: "booking",
  //   component: () => import("@/views/order/Booking.vue"),
  //   props: true,
  // },
  // {
  //   path: "/store/:id",
  //   name: "store",
  //   component: () => import("@/views/store/Main.vue"),
  // },
  {
    path: "/",
    name: "area",
    component: () => import("@/views/area/Main.vue"),
  },
  {
    path: "/step1",
    name: "step1",
    component: () => import("@/views/step/Step1.vue"),
    props: true
  },
  {
    path: "/step2",
    name: "step2",
    component: () => import("@/views/step/Step2.vue"),
  },
  {
    path: "/step3",
    name: "step3",
    component: () => import("@/views/step/Step3.vue"),
  },
  {
    path: "/step4/:id",
    name: "step4",
    component: () => import("@/views/step/Step4.vue"),
    props: true
  },
  {
    path: "/step/error",
    name: "stepError",
    component: () => import("@/views/step/Error.vue"),
    props: true,
  },
  {
    path: "/information/:name",
    name: "informationTemplate",
    component: () => import("@/views/information/Main.vue"),
    props: true,
  },
  {
    path: "/information/howToRent",
    name: "howToRent",
    component: () => import("@/views/information/HowToRent.vue"),
  },
  {
    path: "/information/bikeModel",
    name: "bikeModel",
    component: () => import("@/views/information/BikeModel.vue"),
  },
  {
    path: "/order/list",
    name: "orderList",
    component: () => import("@/views/order/List.vue"),
    props: true,
    meta: { rule: 'Login' }
  },
  {
    path: "/order/:id",
    name: "orderDetail",
    component: () => import("@/views/order/Detail.vue"),
    props: true,
    // meta: { rule: 'Login' }
  },
  {
    path: "/order/:id/info",
    name: "orderInfo",
    component: () => import("@/views/order/EditInfo.vue"),
    props: true,
    meta: { rule: 'Login' }
  },
  // {
  //   path: "/order/:id/product",
  //   name: "orderProduct",
  //   component: () => import("@/views/order/EditProduct.vue"),
  //   props: true,
  // },
  {
    path: "/order/:id/product",
    name: "orderProduct",
    component: () => import("@/views/order/EditProduct.vue"),
    props: true,
    meta: { rule: 'Login' }
  },
  {
    path: "/order/:id/insurance",
    name: "orderInsurance",
    component: () => import("@/views/order/EditInsurance.vue"),
    props: true,
    meta: { rule: 'Login' }
  },
  {
    path: "/hotel",
    name: "hotel",
    component: () => import("@/views/hotel/Detail.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

enum CallbackType {
  Order = "0",
  PaymentConfirmed = "1",
  PaymentCancelled = "2",
  Store = "3",
  Station = "4"
}
router.beforeEach((to, _, next) => {
  // 處理從短網址導向路徑
  if (to.path === "/callback") {
    const type = to.query.t;
    const id = to.query.id as string;
    if (type === CallbackType.Order) {
      next({ name: "orderDetail", params: { id } })
    } else if (type === CallbackType.PaymentConfirmed) {
      const transactionId = to.query.transactionId as string;
      next({ name: "step4", params: { id }, query: { transactionId } })
    } else if (type === CallbackType.PaymentCancelled) {
      // next({ name: "stepError", query: { id } })
      next("/step/error")
    } else if (type === CallbackType.Station) {
      next({ name: 'step1', query: { stationId: id } })
    } else {
      next("/");
    }
    return;
  } else {
    next();
  }
})

export default router;
