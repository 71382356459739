// Create initial state
export const state: {
  darkMode: boolean;
  storeId: string | null;
} = {
  darkMode: false,
  storeId: null
};

// Declare state
export type State = typeof state;
