
import { defineComponent, onMounted, ref } from "vue";
import CloudFun from "@cloudfun/core";
import { arrowForwardOutline } from 'ionicons/icons';

export default defineComponent({
  name: "FaqAccordion",
  setup() {
    const model = CloudFun.current?.model;
    const templates = ref([
      {
        index: "Transaction",
        title: "交易注意事項",
        icon: "icon-node",
        content: "",
      },
      {
        index: "AboutUs",
        title: "關於非租不可",
        icon: "icon-infor",
        content: "",
      },
      {
        index: "Rent",
        title: "騎乘注意事項",
        icon: "icon-warning",
        content: "",
      },
    ]);
    onMounted(async () => {
      for (const item of templates.value) {
        const temp = await model!.dispatch('template/find', item.index);
        item.title = temp.Name;
        item.content = temp.Content.Content;
      }
    });
    return {
      arrowForwardOutline,
      templates
    };
  },
  methods: {},
});
