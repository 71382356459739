
import { defineComponent } from 'vue';
import CloudFun from "@cloudfun/core";

export default defineComponent({
  name: 'App',
  setup() {
    const storeId = CloudFun.current?.policy?.router?.currentRoute.value.query.sid
    CloudFun.current?.model.dispatch("main/setStoreId", storeId);
    return {}
  }
});
