import { App } from "vue"
import DatePicker from "../components/date-picker/Main.vue"
import FaqAccordion from "../components/FaqAccordion.vue";
import FooterBar from "../components/Footer.vue"
import Menu from "../components/menu/Main.vue";
import Pager from "../components/pager/Main.vue";
import TopBar from "../components/top-bar/Main.vue";
import VueNumberInput from "@chenfengyuan/vue-number-input";
import VueQrcode from "@chenfengyuan/vue-qrcode";

import * as IonComponents from "@ionic/vue";

export default (app: App): void => {
  app.component("DatePicker", DatePicker);
  app.component("FaqAccordion", FaqAccordion);
  app.component("FooterBar", FooterBar)
  app.component("Menu", Menu)
  app.component("Pager", Pager)
  app.component("TopBar", TopBar)
  app.component("VueNumberInput", VueNumberInput);
  app.component("VueQrcode", VueQrcode);

  Object.keys(IonComponents).filter(key => /^Ion[A-Z]\w+$/.test(key)).forEach(key => app.component(key, (IonComponents as any)[key]));
}
