import CloudFun, { Application, Policy } from "@cloudfun/core";
import model from "./models";
import App from "./App.vue";
import router from "./router";
import globalComponents from "./views/global-components"
import utils from "./utils";
import { IonicVue, toastController } from "@ionic/vue";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";
import "v-calendar/dist/style.css";
/* Theme variables */
import "./theme/variables.css";

const app = new Application(
  App,
  model,
  new Policy(model, {
    router, loginRoute: '/login',
  })
);


globalComponents(app);
utils(app);
app.use(IonicVue);

// 設定信差中介程序
CloudFun.setMiddlewares({
  info: async (message) => {
    const toast = await toastController.create({
      message: `${typeof message === "string"
        ? "Information"
        : message.subject || "Information"
        }: ${typeof message === "string" ? message : message.content}`,
      color: "primary",
      position: "bottom",
      duration: 3000
    });
    await toast.present();
  },
  warning: async (message: any) => {
    const toast = await toastController.create({
      message: `${typeof message === "string"
        ? "Warning"
        : message.subject || "Warning"
        }: ${typeof message === "string" ? message : message.content}`,
      color: "warning",
      position: "bottom",
      duration: 3000
    });
    await toast.present();
  },
  error: async (message) => {
    const toast = await toastController.create({
      message: `${typeof message === "string"
        ? "Error"
        : message.subject || "Error"
        }: ${typeof message === "string" ? message : message.content}`,
      color: "danger",
      position: "bottom",
      duration: 3000
    });
    await toast.present();
  },
  "application.error": (error: any) => console.log(error),
});

router.isReady().then(() => {
  app.mount("#app");
});
