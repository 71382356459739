import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_datetime = _resolveComponent("ion-datetime")!
  const _component_ion_modal = _resolveComponent("ion-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_input, _mergeProps(_ctx.$attrs, {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showPicker = true)),
      value: _ctx.displayValue,
      placeholder: _ctx.placeholder
    }), null, 16, ["value", "placeholder"]),
    _createVNode(_component_ion_modal, {
      class: "dateTime",
      isOpen: _ctx.showPicker,
      onDidDismiss: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showPicker = false))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_datetime, {
          class: "datetime",
          "prefer-wheel": true,
          mode: "md",
          presentation: _ctx.type,
          value: _ctx.modelValue,
          onIonChange: _ctx.updateValue,
          size: "cover",
          locale: "zh-TW",
          showDefaultButtons: true,
          max: _ctx.max,
          doneText: "確定",
          cancelText: "取消"
        }, null, 8, ["presentation", "value", "onIonChange", "max"])
      ]),
      _: 1
    }, 8, ["isOpen"])
  ], 64))
}