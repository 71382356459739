import CloudFun from "@cloudfun/core";
import { App } from "vue";
import faker from "./faker";
import helper, { helper as $h } from "./helper";
import lodash from "./lodash";
import phoneNumber, { CountryCode } from "libphonenumber-js";

export default (app: App): void => {
  app.use(faker);
  app.use(helper);
  app.use(lodash);

  const filters = {
    formatDate: $h.formatDate,
    formatPhone: (number: string, country: CountryCode = "TW") =>
    number && phoneNumber(number, country)?.formatNational()?.replaceAll(' ', ''),
  }

  Object.assign(CloudFun.utils, filters);
};
