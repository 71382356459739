import { App } from "vue";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";

dayjs.extend(duration);

const helpers = {
  cutText(text: string, length: number): string {
    if (text.split(" ").length > 1) {
      const string = text.substring(0, length);
      const splitText = string.split(" ");
      splitText.pop();
      return splitText.join(" ") + "...";
    } else {
      return text;
    }
  },
  formatDate(date: string, format = "YYYY-MM-DD HH:mm"): string {
    return date && dayjs(date).format(format);
  },
  capitalizeFirstLetter(string: string): string {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    } else {
      return "";
    }
  },
  onlyNumber(string: string): string {
    if (string) {
      return string.replace(/\D/g, "");
    } else {
      return "";
    }
  },
  formatCurrency(number: number): string {
    if (number) {
      const formattedNumber = number.toString().replace(/\D/g, "");
      const rest = formattedNumber.length % 3;
      let currency = formattedNumber.substr(0, rest);
      const thousand = formattedNumber.substr(rest).match(/\d{3}/g);
      let separator;

      if (thousand) {
        separator = rest ? "." : "";
        currency += separator + thousand.join(".");
      }

      return currency;
    } else {
      return "";
    }
  },
  timeAgo(time: string): string | false {
    const date = new Date(
      (time || "").replace(/-/g, "/").replace(/[TZ]/g, " ")
    );
    const diff = (new Date().getTime() - date.getTime()) / 1000;
    const dayDiff = Math.floor(diff / 86400);

    if (isNaN(dayDiff) || dayDiff < 0 || dayDiff >= 31) {
      return dayjs(time).format("MMMM DD, YYYY");
    }

    return (
      (dayDiff === 0 &&
        ((diff < 60 && "just now") ||
          (diff < 120 && "1 minute ago") ||
          (diff < 3600 && Math.floor(diff / 60) + " minutes ago") ||
          (diff < 7200 && "1 hour ago") ||
          (diff < 86400 && Math.floor(diff / 3600) + " hours ago"))) ||
      (dayDiff === 1 && "Yesterday") ||
      (dayDiff < 7 && dayDiff + " days ago") ||
      (dayDiff < 31 && Math.ceil(dayDiff / 7) + " weeks ago")
    );
  },
  diffTimeByNow(
    time: string
  ): {
    days: string | number;
    hours: string | number;
    minutes: string | number;
    seconds: string | number;
  } {
    const startDate = dayjs(dayjs().format("YYYY-MM-DD HH:mm:ss").toString());
    const endDate = dayjs(dayjs(time).format("YYYY-MM-DD HH:mm:ss").toString());

    const duration = dayjs.duration(endDate.diff(startDate));
    const milliseconds = Math.floor(duration.asMilliseconds());

    const days = Math.round(milliseconds / 86400000);
    const hours = Math.round((milliseconds % 86400000) / 3600000);
    let minutes = Math.round(((milliseconds % 86400000) % 3600000) / 60000);
    const seconds = Math.round(
      (((milliseconds % 86400000) % 3600000) % 60000) / 1000
    );

    if (seconds < 30 && seconds >= 0) {
      minutes += 1;
    }

    return {
      days: days.toString().length < 2 ? "0" + days : days,
      hours: hours.toString().length < 2 ? "0" + hours : hours,
      minutes: minutes.toString().length < 2 ? "0" + minutes : minutes,
      seconds: seconds.toString().length < 2 ? "0" + seconds : seconds,
    };
  },
  isset(obj: Record<string, unknown> | number | string): boolean | number {
    if (obj !== null && obj !== undefined) {
      if (typeof obj === "object" || Array.isArray(obj)) {
        return Object.keys(obj).length;
      } else {
        return obj.toString().length;
      }
    }

    return false;
  },
  toRaw(obj: Record<string, any>): any {
    return JSON.parse(JSON.stringify(obj));
  },
  randomNumbers(from: number, to: number, length: number): Array<number> {
    const numbers = [0];
    for (let i = 1; i < length; i++) {
      numbers.push(Math.ceil(Math.random() * (from - to) + to));
    }

    return numbers;
  },
  checkID(value: any) {
    const areaCodes = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J', 'K', 'L', 'M', 'N', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'X', 'Y', 'W', 'Z', 'I', 'O'];//台灣各縣市的代碼
    const mNumbers = [1, 9, 8, 7, 6, 5, 4, 3, 2, 1];//用來作驗證的相乘數字
    const c = new Array(2);//用來儲存字母換算成數字的個位數和十位數
    let firstLetter, secondLetter;//用來儲存第一個字母
    let e = 10;//字母換算成數字從10開始
    let f;//用來儲存最後一個數字-檢查碼
    let g = 0;//用來儲存加總起來的數字
    let h = /^[a-z](1|2)\d{8}$/i;//正規表示式
    let isValid = true;

    //符合台灣身分證格式
    if (!value) {
      isValid = true;
    } else if (value.search(h) == -1) {
      isValid = false;
    } else {
      firstLetter = value.charAt(0).toUpperCase();
      f = value.charAt(9);

      for (let i = 0; i < 26; i++) {
        if (firstLetter == areaCodes[i]) {//a==a
          e = e + i;  //10
          c[0] = Math.floor(e / 10); //1
          c[1] = e - (c[0] * 10); //10-(1*10)
          break;
        }
      }
      //將對應順序的數字相乘並加總起來
      for (let i = 0; i < mNumbers.length; i++) {
        if (i < 2) {
          g += c[i] * mNumbers[i];
        } else {
          g += parseInt(value.charAt(i - 1)) * mNumbers[i];
        }
      }
      if ((g % 10) == f) {
        isValid = true;
      }
      else if ((10 - (g % 10)) != f) {
        isValid = false;
      }
    }

    //符合新式外籍居留證格式
    if (!isValid) {
      e = 10;
      g = 0;
      h = /^[a-z](8|9)\d{8}$/i;
      if (value.search(h) == -1) {
        isValid = false;
      } else {
        firstLetter = value.charAt(0).toUpperCase();
        f = value.charAt(9);

        for (let i = 0; i < 26; i++) {
          if (firstLetter == areaCodes[i]) {//a==a
            e = e + i;  //10
            c[0] = Math.floor(e / 10); //1
            c[1] = e - (c[0] * 10); //10-(1*10)
            break;
          }

        }
        //將對應順序的數字相乘並加總起來
        for (let i = 0; i < mNumbers.length; i++) {
          if (i < 2) {
            g += c[i] * mNumbers[i];
          } else {
            g += parseInt(value.charAt(i - 1)) * mNumbers[i];
          }
        }

        if ((g % 10) == 0 || (10 - g % 10) == f) {
          isValid = true;
        } else {
          isValid = false;
        }
      }
    }
    //符合舊式外籍居留證格式
    if (!isValid) {
      e = 10;
      g = 0;
      h = /^[A-Z]{2}[0-9]{8}$/;
      if (value.search(h) == -1) {
        isValid = false;
      } else {
        firstLetter = value.charAt(0).toUpperCase();
        secondLetter = value.charAt(1).toUpperCase();
        f = value.charAt(9);

        for (let i = 0; i < 26; i++) {
          if (firstLetter == areaCodes[i]) {//a==a
            e = e + i;  //10
            c[0] = Math.floor(e / 10); //1
            c[1] = e - (c[0] * 10); //10-(1*10)
          }
          if (secondLetter == areaCodes[i]) {
            c[2] = (i % 10);
          }
        }
        //將對應順序的數字相乘並加總起來
        for (let i = 0; i < mNumbers.length; i++) {
          if (i < 3) {
            g += c[i] * mNumbers[i];
          } else {
            g += parseInt(value.charAt(i - 1)) * mNumbers[i];
          }
        }

        if ((g % 10) == 0 || (10 - g % 10) == f) {
          isValid = true;
        } else {
          isValid = false;
        }
      }
    }
    return isValid;
  }
};

const install = (app: App): void => {
  app.config.globalProperties.$h = helpers;
};

export { install as default, helpers as helper };
