import { Model } from "@cloudfun/core";
import { main, Store as MainStore, State as MainState } from "./main";
import actionLog from "./action-log";
import area from "./area";
import banner from "./banner";
import bannerPosition from "./banner-position";
import calendar from "./calendar";
import configuration from "./configuration";
import comment from "./comment";
import files from "./files";
import insuranceRecord from "./insurance-record";
import member from "./member";
import news from "./news";
import order from "./order";
import orderItem from "./orderItem";
import payment from "./payment";
import picture from "./picture";
import pictureAlbum from "./picture-album";
import product from "./product";
import station from "./station";
import store from "./store";
import system from "./system";
import template from "./template";
import verification from "./verification";
import category from "./category";

const model = new Model("Fastbike", {
  main,
  actionLog,
  area,
  banner,
  bannerPosition,
  configuration,
  comment,
  files,
  insuranceRecord,
  news,
  member,
  order,
  orderItem,
  payment,
  picture,
  pictureAlbum,
  product,
  station,
  store,
  system,
  template,
  verification,
  calendar,
  category,
});

const backendServer = process.env.VUE_APP_BACKEND_URL;
model.clients.authorized = model.createHttpClient(`${backendServer}/api`, true);
model.clients.unauthorized = model.createHttpClient(`${backendServer}/api`);
model.onLogin = (data) =>
  model.clients.unauthorized.post("Portal/System/Login", {
    account: data.account,
    password: data.password,
    token: data.password,
    validateCode: data.password,
    LastVisitedUri: data.token,
    LoginIp: data.captcha,
  });
model.onLogout = () => model.clients.authorized.post("Portal/System/Logout");
model.onReloadUser = () => model.clients.authorized.post("Portal/System/CurrentUser");
model.onReloadEnums = () =>
  model.clients.unauthorized.get("Portal/System/Enumerations");
model.onLog = (message) =>
  model.clients.unauthorized.post("Portal/System/Log", message);

export type State = { main: MainState };
export type Store = MainStore<Pick<State, "main">>;

export default model;
