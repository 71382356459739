
import { defineComponent, PropType, ref, computed } from "vue";
import CloudFun from "@cloudfun/core"

const dateFomats = {
  "date": "YYYY-MM-DD",
  "time": "HH:mm",
  "date-time": "YYYY-MM-DD HH:mm",
  "time-date": "YYYY-MM-DD HH:mm"
}

export default defineComponent({
  name: "DatePicker",
  props: {
    modelValue: [String, Date],
    placeholder: String,
    type: {
      type: String as PropType<"date" | "date-time" | "time" | "time-date">,
      // date" | "date-time" | "month" | "month-year" | "time" | "time-date" | "year"
      default: "date"
    },
    max: [String, Date]
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const showPicker = ref(false);
    const displayValue = computed(() => CloudFun.utils.formatDate(props.modelValue, dateFomats[props.type]));

    const updateValue = (evt: CustomEvent) => {
      showPicker.value = false;
      emit("update:modelValue", evt.detail.value ? evt.detail.value : props.max)
    }
    return {
      displayValue,
      showPicker,
      updateValue
    };
  }
});
